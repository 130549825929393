import * as React from 'react'
import * as d3 from 'd3'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import { ExplanatoryText } from '../../components/scrolly'
import EmailSubscribe from '../../components/email-subscribe';
import HorzBar from '../../charts/lines/horz-bar';

const horzBarTest = () => {

  const allStates = [
    {
      "x": "Calif.",
      "y": 25171540.2,
      "pol_lean": -22
    },
    {
      "x": "N.Y.",
      "y": 23997997.8,
      "pol_lean": -22
    },
    {
      "x": "Ill.",
      "y": 21720251.7,
      "pol_lean": -13
    },
    {
      "x": "Mich.",
      "y": 16700250.6,
      "pol_lean": -6
    },
    {
      "x": "Ohio",
      "y": 14925278.7,
      "pol_lean": 6
    },
    {
      "x": "N.J.",
      "y": 12263013,
      "pol_lean": -11
    },
    {
      "x": "Pa.",
      "y": 12149260.2,
      "pol_lean": 1
    },
    {
      "x": "Tex.",
      "y": 11216124.9,
      "pol_lean": 17
    },
    {
      "x": "Wis.",
      "y": 7597281.6,
      "pol_lean": 3
    },
    {
      "x": "Colo.",
      "y": 7498571.4,
      "pol_lean": 0
    },
    {
      "x": "Ind.",
      "y": 7344522,
      "pol_lean": 17
    },
    {
      "x": "Minn.",
      "y": 7143533.1,
      "pol_lean": -1
    },
    {
      "x": "Mass.",
      "y": 6606281.7,
      "pol_lean": -23
    },
    {
      "x": "Ga.",
      "y": 6578831.7,
      "pol_lean": 13
    },
    {
      "x": "Mo.",
      "y": 5489121.6,
      "pol_lean": 21
    },
    {
      "x": "Wash.",
      "y": 4786950.6,
      "pol_lean": -15
    },
    {
      "x": "Md.",
      "y": 4242342.6,
      "pol_lean": -23
    },
    {
      "x": "Va.",
      "y": 4131993.6,
      "pol_lean": -7
    },
    {
      "x": "Utah",
      "y": 4073085.9,
      "pol_lean": 30
    },
    {
      "x": "Tenn.",
      "y": 3601824.3,
      "pol_lean": 28
    },
    {
      "x": "Iowa",
      "y": 3518431.2,
      "pol_lean": 9
    },
    {
      "x": "N.C.",
      "y": 3516070.5,
      "pol_lean": 4
    },
    {
      "x": "Kans.",
      "y": 3420983.7,
      "pol_lean": 21
    },
    {
      "x": "Okla.",
      "y": 3333418.2,
      "pol_lean": 33
    },
    {
      "x": "Conn.",
      "y": 2673684.9,
      "pol_lean": -10
    },
    {
      "x": "Nev.",
      "y": 2525894.1,
      "pol_lean": 1
    },
    {
      "x": "Ky.",
      "y": 2523258.9,
      "pol_lean": 24
    },
    {
      "x": "Oreg.",
      "y": 2497236.3,
      "pol_lean": -7
    },
    {
      "x": "Ariz.",
      "y": 2279173.5,
      "pol_lean": 8
    },
    {
      "x": "Nebr.",
      "y": 2031245.1,
      "pol_lean": 25
    },
    {
      "x": "N. Mex.",
      "y": 2000336.4,
      "pol_lean": -8
    },
    {
      "x": "La.",
      "y": 1760423.4,
      "pol_lean": 19
    },
    {
      "x": "Idaho",
      "y": 1683673.2,
      "pol_lean": 33
    },
    {
      "x": "Ark.",
      "y": 1663579.8,
      "pol_lean": 26
    },
    {
      "x": "S.C.",
      "y": 1598193.9,
      "pol_lean": 16
    },
    {
      "x": "Ala.",
      "y": 1546972.2,
      "pol_lean": 27
    },
    {
      "x": "W. Va.",
      "y": 1254629.7,
      "pol_lean": 33
    },
    {
      "x": "Mont.",
      "y": 1194843.6,
      "pol_lean": 18
    },
    {
      "x": "Alaska",
      "y": 1155590.1,
      "pol_lean": 16
    },
    {
      "x": "Miss.",
      "y": 1131543.9,
      "pol_lean": 15
    },
    {
      "x": "R.I.",
      "y": 1003462.2,
      "pol_lean": -24
    },
    {
      "x": "Fla.",
      "y": 951197.4,
      "pol_lean": 6
    },
    {
      "x": "S. Dak.",
      "y": 725119.2,
      "pol_lean": 28
    },
    {
      "x": "Wyo.",
      "y": 711943.2,
      "pol_lean": 45
    },
    {
      "x": "N. Dak.",
      "y": 656878.5,
      "pol_lean": 34
    },
    {
      "x": "Del.",
      "y": 595719.9,
      "pol_lean": -12
    },
    {
      "x": "N.H.",
      "y": 406424.7,
      "pol_lean": 1
    },
    {
      "x": "Vt.",
      "y": 209059.2,
      "pol_lean": -24
    },
    {
      "x": "Maine",
      "y": 162613.8,
      "pol_lean": -3
    },
    {
      "x": "Hawaii",
      "y": 33104.7,
      "pol_lean": -36
    }
  ]

  const preemptionStates = [
    {
      "x": "Ohio",
      "y": 14925278.7,
      "pol_lean": 6
    },
    {
      "x": "Tex.",
      "y": 11216124.9,
      "pol_lean": 17
    },
    {
      "x": "Ind.",
      "y": 7344522,
      "pol_lean": 17
    },
    {
      "x": "Ga.",
      "y": 6578831.7,
      "pol_lean": 13
    },
    {
      "x": "Mo.",
      "y": 5489121.6,
      "pol_lean": 21
    },
    {
      "x": "Utah",
      "y": 4073085.9,
      "pol_lean": 30
    },
    {
      "x": "Tenn.",
      "y": 3601824.3,
      "pol_lean": 28
    },
    {
      "x": "Iowa",
      "y": 3518431.2,
      "pol_lean": 9
    },
    {
      "x": "Kans.",
      "y": 3420983.7,
      "pol_lean": 21
    },
    {
      "x": "Okla.",
      "y": 3333418.2,
      "pol_lean": 33
    },
    {
      "x": "Ky.",
      "y": 2523258.9,
      "pol_lean": 24
    },
    {
      "x": "Ariz.",
      "y": 2279173.5,
      "pol_lean": 8
    },
    {
      "x": "La.",
      "y": 1760423.4,
      "pol_lean": 19
    },
    {
      "x": "Ark.",
      "y": 1663579.8,
      "pol_lean": 26
    },
    {
      "x": "Ala.",
      "y": 1546972.2,
      "pol_lean": 27
    },
    {
      "x": "W. Va.",
      "y": 1254629.7,
      "pol_lean": 33
    },
    {
      "x": "Miss.",
      "y": 1131543.9,
      "pol_lean": 15
    },
    {
      "x": "Fla.",
      "y": 951197.4,
      "pol_lean": 6
    },
    {
      "x": "Wyo.",
      "y": 711943.2,
      "pol_lean": 45
    },
    {
      "x": "N.H.",
      "y": 406424.7,
      "pol_lean": 1
    }
  ]

  const blueStates = [
    {
      "x": "Calif.",
      "y": 25171540.2,
      "pol_lean": -22
    },
    {
      "x": "N.Y.",
      "y": 23997997.8,
      "pol_lean": -22
    },
    {
      "x": "Mass.",
      "y": 6606281.7,
      "pol_lean": -23
    },
    {
      "x": "Wash.",
      "y": 4786950.6,
      "pol_lean": -15
    }
  ]

  // color scale for political lean layered on state bar charts
  const barColor = d3.scaleDiverging(t => d3.interpolateRdBu(1 - t))
    .domain([-36, 0, 45])
    .range(["#0692D5", "#F3F4F6", "#C93135"])
  
  const allStatesWithColor = allStates.map((d) => {
    d.hex = barColor(d.pol_lean)
    return d
  })

  const preemptionWithColor = preemptionStates.map((d) => {
    d.hex = barColor(d.pol_lean)
    return d
  })

  const blueStatesWithColor = blueStates.map((d) => {
    d.hex = barColor(d.pol_lean)
    return d
  })


  return (
    
    <Layout
      title={"The Politics of Banning Natural Gas"}
      description={"A data visualization exploring the potential impact of natural gas bans and preemptions laws."}
      image={"/static/22dd3fcbbf07869f17aa3b576c32cd07/c2d17/gas-meters.png"}
    >

      <section id="hero" className='mt-8 max-w-2xl mx-auto'>
        <div className='flex flex-col items-center pt-8 pb-4 px-4'>
          <h1 className='text-4xl lg:text-5xl'>The Politics of Banning Natural Gas</h1>
          <p className='mt-2 font-normal text-xl sm:text-2xl'>A data visualization exploring the potential impact of natural gas bans and preemptions laws.</p>
          <StaticImage src="../../images/article-images/gas-meters.png" alt="Cover image" className="mb-10 mt-4" />
        </div>
      </section>

      <ExplanatoryText>
        <p>Last year there were a number of bills passed in state legislatures and city 
          councils across the country targeting residential natural gas use. Democrats 
          in states like California, New York, Massachusetts, and Washington introduced 
          or passed bills aimed at updating building codes to encourage all-electric homes. 
          New York City and a few dozen municipalities in California went so far as to 
          ban natural gas in new construction entirely.</p>
        <p>Fearing a broader trend across the country, the natural gas industry went on the 
          defensive and convinced 20 Republican states to pass “preemption laws” that prevent 
          cities from banning gas.</p>
        <p>But the scale of residential gas emissions in each state varies widely. 
          Phasing out natural gas in California and New York, for example, would have a larger 
          effect than phasing out natural gas in 30 smaller states. </p>
        <p>In order to better understand the potential impact of these policies and any future 
          legislation, I created the chart below, which shows annual emissions from residential 
          natural gas by state. The color of each bar chart shows the political leaning of voters 
          in each state ranging from Hawaii’s D+32 to Wyoming’s R+50.</p>
      </ExplanatoryText>

      <div id="legend" className='mt-2 flex flex-col items-center text-sm text-slate-600 font-normal font-sans'>
        <div className='font-semibold'>Partisan lean</div>
        <div className='pt-1 flex flex-row justify-center items-center'>
          <div>D+32</div>
          <div className='mx-2 h-2 w-24 bg-gradient-to-r from-[#0692D5] via-[#F3F4F6] to-[#C93135]'></div>
          <div>R+50</div>
        </div>
      </div>

      <HorzBar
        data={allStatesWithColor}
        height={1000}
        chartTitle={"CO2 emissions from residential natural gas (metric tons per year)"}
        titleStyling={"font-light text-xl"}
        containerStyling={"mx-auto py-12 w-11/12 sm:w-3/4"}
        yPadding={0.1}
        xMax={26000000}
        xFormat={".2~s"}
        marginLeft={50}
        marginRight={50}
        dataLabels={true}
      />

      <ExplanatoryText>
        <p>Why does all this matter? First, there’s no path to zero emissions in this sector – or 
          any other for that matter – that doesn’t involve policy. In other words, decarbonizing 
          residential natural gas will be inherently political. Secondly, in America, states have 
          an outsized influence on energy policy. It’s the states that regulate utilities, not the 
          federal government.</p>
        <p>For electrification advocates there’s good news and bad news in the data above. The good 
          news is that about half of emissions – 128 million tons – come from states that lean more 
          than 5 points blue. If any states act boldly to decarbonize their housing, it’s likely to 
          be these ones.</p>
          <p>The four states – California, New York, Massachusetts, and Washington – that introduced 
            or passed electrification bills last year make up 24% of residential gas emissions, or 
            60.5 million tons per year. </p>
      </ExplanatoryText>

      <HorzBar
        data={blueStatesWithColor}
        height={130}
        chartTitle={"Residential gas emissions in states with new regulations (metric tons per year)"}
        titleStyling={"font-light text-xl"}
        containerStyling={"mx-auto py-12 w-11/12 sm:w-3/4"}
        yPadding={0.1}
        xFormat={".2~s"}
        xMax={26000000}
        marginLeft={50}
        marginRight={50}
        dataLabels={true}
      />

      <ExplanatoryText>
        <p>20 percent of emissions — 41 million tons – come from states that lean between D+5 and R+5. 
          It’s unlikely that any of these states will lead the electrification movement. But they are 
          also less likely to pass anything as overtly pro-gas as a preemption law. New Hampshire (R+1) 
          is the only state in this category that has done so.</p>
        <p>Now for the bad news. </p>
        <p>A third of emissions – 86 million tons – come from states that lean more than 5 points red. 
          78 million tons of these emissions are in the 19 Republican states that passed preemption laws 
          last year. </p>
      </ExplanatoryText>

      <HorzBar
        data={preemptionWithColor}
        height={500}
        chartTitle={"Residential gas emissions in states with preemption laws (metric tons per year)"}
        titleStyling={"font-light text-xl"}
        containerStyling={"mx-auto py-12 w-11/12 sm:w-3/4"}
        yPadding={0.1}
        xFormat={".2~s"}
        xMax={26000000}
        marginLeft={50}
        marginRight={50}
        dataLabels={true}
      />

      <ExplanatoryText>
        <p>To put this in perspective, if the stoves, furnaces, and other gas appliances in these states 
          were a country, they would rank 45th in terms of annual emissions just after Qatar and ahead 
          of Colombia.</p>
        <p>All-electric building codes and preemption laws are just two policies. The bills passed last 
          year are only part of the larger electrification story. But I think they are a sort of prequel 
          to the political battles we can expect over the coming decades. </p>
        <p className='text-center'> ———</p>
        <p>Next week I plan to do a similar analysis on residential natural gas emissions in America’s 
          largest cities. In the meantime, <a href="https://twitter.com/curious_founder">let me know</a> what you thought of this story.
        </p>
      </ExplanatoryText>

      <section id="newsletter-cta" className='bg-slate-100'>
        <div className="text-center flex flex-col my-12 pt-6 pb-12 px-3 sm:px-3 md:px-6 lg:px-12">
            <div className="mx-auto max-w-2xl">
              <h4>Sign up to receive the next story in your inbox</h4>
              <p>
                Every week we write a newsletter packed with all kinds of climate data, research, and insights. 
                We think you'll love it.
              </p>
            </div>
            <EmailSubscribe />
        </div>
      </section>

      <section className='pb-12'>
        <ExplanatoryText>
          <h4>Notes and sources</h4>
          <p>In order to calculate residential gas emissions in each state I used <a href="https://www.eia.gov/dnav/ng/ng_cons_sum_a_EPG0_vrs_mmcf_a.htm">
             this EIA data</a> and the <a href="https://www.epa.gov/energy/greenhouse-gas-equivalencies-calculator-revision-history">
             EPA's Greenhouse Gas Equivalence Caluclator.</a>
          </p>
          <p>The charts above don't include methane emissions, which could increase each states total greenhouse gas emissions by anywhere from 20% to 60% 
            in terms of CO2 equivalents depending on the leak rate. Unfortunately calculating this is impossible today since there are no reliable studies 
            that estimate the leak rate in each state's residential gas supply. I look forward to the angry emails and tweets 
            I will no doubt receive for not including methane emissions in the charts above.
          </p>
          <p>The political data comes from <a href="https://fivethirtyeight.com/features/how-red-or-blue-is-your-state-your-congressional-district/">this FiveThirtyEight index </a>
           which measures the partisanship of each state.
          </p>
        </ExplanatoryText>
      </section>

      {/* <HorzBar
        data={byPopWithColor}
        height={1000}
        chartTitle={"CO2 emissions per capita from residential natural gas (metric tons per year)"}
        titleStyling={"font-light text-xl"}
        containerStyling={"mx-auto py-12 w-11/12 sm:w-3/4"}
        yPadding={0.1}
        xFormat={".2"}
        marginLeft={50}
        marginRight={50}
        dataLabels={true}
      />

      <HorzBar
        data={topCitiesBar}
        height={500}
        chartTitle={"CO2 emissions from residential natural gas (metric tons per year)"}
        titleStyling={"font-light text-xl"}
        barFill={"#11B981"}
        containerStyling={"mx-auto py-12 w-11/12 sm:w-3/4"}
        yPadding={0.1}
        xFormat={".2~s"}
        marginLeft={75}
        marginRight={50}
        dataLabels={true}
      />

      <UsMapDots
        data={topCitiesMap}
        dotSizeAccessor={dotAccessor}
        tooltipDataAccessor={cityAccessor}
        rangeLower={250}                // this gets divided by screen width 
        rangeUpper={50}                // this gets divided by screen width 
        mapFill={"#E0E2E4"}
        mapStroke={"#F5F5F6"}
        dotFill={"#2F394B"}
        dotOpacity={0.7}
        containerStyling={"sticky mx-auto m-0 top-25vh sm:top-1vh w-3/4 h-50vh sm:h-90vh bottom-25vh sm:bottom-10vh"}
      /> 
      
      */}


    </Layout>
  )

}

export default horzBarTest